<template>
  <div class="g_page_box">
    <h2 class="h2">普通文章：</h2>
    <img class="img" :src="`./img/example/q_1.jpeg`" />
    <h2 class="h2">数字人：</h2>
    <img class="img" :src="`./img/example/q_2.jpeg`" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      code: null
    }
  },
  computed: {
  },
  components: {
  },
  watch: {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
.g_page_box {
  padding: 20px 20px 40px;
  overflow: auto;
}
.h2 {
  margin-top: 20px;
  font-size: 18px;
  color: #333;
  font-weight: bold;
}
.img {
  width: 100%;
}
</style>
